import { PageTitle } from '@/components';
import { ScoreCardSkeletonLoader } from '@/components/scorecard/scoreCardSkeleton';
import { ScorecardSummary } from '@/components/scorecard/scorecardSummary';
import { ScorecardTable } from '@/components/scorecard/scorecardTable';
import { env } from '@/constants';
import { usePageContext } from '@/contexts';
import { AnimationHelper } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { usePatientScoreCardPage } from './usePatientScoreCardPage';
import { TableIdentifier } from '@/components/scorecard/useScoreCardTable';

export function PatientScorecardPage() {
  const { setPageTitle, setIsBackButtonVisible } = usePageContext();
  const { isLoading, allAnalytesCards, atriaTargetsCards } = usePatientScoreCardPage();

  useEffect(() => {
    setPageTitle('Results');
    setIsBackButtonVisible(false);
  }, [setIsBackButtonVisible, setPageTitle]);

  const isNotEmpty = useMemo(
    () => allAnalytesCards?.length && atriaTargetsCards?.length,
    [allAnalytesCards?.length, atriaTargetsCards?.length]
  );

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle title='Lab trends' />
      <ScorecardSummary analytes={allAnalytesCards} />

      <AnimatePresence>
        {isLoading ? (
          <ScoreCardSkeletonLoader />
        ) : (
          isNotEmpty && (
            <motion.div
              initial={{ translateY: -20, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1, transition: { duration: 0.6 } }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6, ease: AnimationHelper.ease }}
              className='max-w-full flex flex-col gap-[10px]'
            >
              {env.APP_FEATURE_FLAGS.IS_TO_SHOW_ATRIA_TARGETS_TABLE && atriaTargetsCards && (
                <ScorecardTable
                  identifier={TableIdentifier.AtriaBiomarkers}
                  filterPosition='horizontal'
                  title='Atria key biomarkers'
                  description='These are some of the most important values to  monitor for healthspan and lifespan.'
                  cards={atriaTargetsCards}
                />
              )}
              {allAnalytesCards && (
                <ScorecardTable
                  identifier={TableIdentifier.AllBiomarkers}
                  filterPosition='vertical'
                  title='All biomarkers'
                  description='These are all the values your doctors are monitoring.'
                  cards={allAnalytesCards}
                />
              )}
            </motion.div>
          )
        )}
      </AnimatePresence>
    </div>
  );
}

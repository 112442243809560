import { Button } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';

import { useContactDialogContext, usePageContext } from '@/contexts';
import { AtriaIconButton } from '../atriaLibrary';
import { BackButton, ContactButton } from '../button';
import { useMediaQuery } from 'react-responsive';

type Props = {
  onSidebarToggle: VoidFunction;
};

export function Navbar({ onSidebarToggle }: Props) {
  const {
    pageTitle,
    isPageTitleVisible,
    isBackButtonVisible,
    actions,
    setSidebarVisibility,
    isSidebarVisible,
    backButtonLabel,
    isContactButtonVisible,
  } = usePageContext();
  const { buttonTitle } = useContactDialogContext();
  const isSmallDesktop = useMediaQuery({ query: '(max-width: 1024px)' });

  const HamburgerButton = useCallback(() => {
    const handleOnSidebarToggle = () => {
      setSidebarVisibility(!isSidebarVisible);
      onSidebarToggle();
    };
    return <AtriaIconButton icon='menu' iconClassName='text-2xl' onClick={handleOnSidebarToggle} />;
  }, [onSidebarToggle, setSidebarVisibility, isSidebarVisible]);

  return (
    <header className='relative flex-1 flex items-center justify-between mb-5'>
      <div className='absolute top-6'>
        {isBackButtonVisible ? <BackButton label={backButtonLabel} /> : <HamburgerButton />}
      </div>
      <div className='flex-1 flex items-center justify-between max-w-[2000px] m-auto gap-4 pl-12 md:px-16 pt-5'>
        <div>
          {isPageTitleVisible && (
            <h1 className='text-xl md:text-2xl font-medium text-kelp-light'>{pageTitle}</h1>
          )}
        </div>
        <div className='flex gap-2'>
          {actions?.buttons?.map((button) => (
            <Button
              key={button.label}
              variant={button.variant || 'tertiary'}
              label={button.label}
              icon={button.icon}
              onClick={button.onClick}
              iconVisibility='mobile-only'
              textVisibility={isSmallDesktop ? 'invisible' : 'desktop-only'}
            />
          ))}
          {isContactButtonVisible && (
            <ContactButton topic={pageTitle} label={buttonTitle} icon='email' />
          )}
        </div>
      </div>
    </header>
  );
}

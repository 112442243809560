import { MemberPortal } from '@/@types';
import { cn, CollapseTableItem, Tooltip } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { ScoreCardCell } from './scoreCardCell';
import { ScorecardChartRow } from './scorecardChartRow';
import { ScoreCardEmptyCell } from './scoreCardEmptyCell';
import { ScoreCardHistoricalCell } from './scoreCardHistoricalCell';
import { ScoreCardStatusCell } from './scoreCardStatusCell';

type CollapseTableHeaderItem<T = CollapseTableItem> = {
  key: string;
  label: string | React.ReactNode;
  width?: string;
  className?: string;
  renderItem?: (item: T, key: string) => React.ReactNode;
  freeze?: boolean;
  rowGroup?: boolean;
};

type Props = { cards: MemberPortal.FindAllBiomarkers.AllAnalytesTableList };
type Biomarker = MemberPortal.AtriaTargets.Biomarker;
type BiomarkerHistorical = Biomarker['historical'];
type Header = MemberPortal.AtriaTargets.HeaderItem;

export enum TableIdentifier {
  AtriaBiomarkers,
  AllBiomarkers,
}

export function useScoreCardTable({ cards }: Props) {
  const separators = ['Historical findings'];

  const navigateToResult = useCallback((documentId: number) => {
    window.open(`/results/lab/${documentId}`, '_blank');
  }, []);

  const getTableFilters = useCallback(
    (tableId: TableIdentifier) => [
      {
        group: 1,
        type: 'select',
        label: 'All',
        validateFn: () => true,
      },
      {
        group: 1,
        type: 'select',
        label: tableId === TableIdentifier.AtriaBiomarkers ? 'Optimal' : 'Optimal / Normal',
        validateFn: (item: CollapseTableItem) => {
          return (
            item?.status &&
            ['normal', 'optimal', 'highly optimal'].includes(item.status.toLowerCase())
          );
        },
      },
      {
        group: 1,
        type: 'select',
        label: 'Out of range',
        validateFn: (item: CollapseTableItem) => {
          return (
            item?.status && ['low', 'high', 'out of range'].includes(item.status.toLowerCase())
          );
        },
      },
      {
        group: 1,
        type: 'text',
        label: 'Metric',
        key: 'metric',
        validateFn: (item: CollapseTableItem, value: string) => {
          if (!value) return true;
          return item?.metric && item.metric.toLowerCase().includes(value?.toLowerCase());
        },
      },
      ...cards
        .filter((card) => card.tag !== 'atriaTargets')
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((card) => ({
          group: 2,
          type: 'select',
          label: card.title,
          key: card.tag,
          validateFn: (item: CollapseTableItem) => {
            return item.__tag__ === card.tag;
          },
        }))
        .sort((a, b) => (a.label === 'Other' ? 1 : b.label === 'Other' ? -1 : 0)),
    ],
    [cards]
  );

  const filterGroups = useMemo(
    () => [
      { group: 1, label: 'Filters' },
      { group: 2, label: 'Categories' },
    ],
    []
  );

  const getAnalyteHeaders = useCallback(
    (header: CollapseTableHeaderItem[]): CollapseTableHeaderItem<Biomarker>[] => {
      return header?.map(({ key, label, ...rest }) => {
        if (key === 'metric') {
          return {
            key,
            label: 'Biomarker',
            className: 'min-w-[185px] w-full',
            freeze: true,
            renderItem: (item: any) => {
              return (
                <div className='flex flex-row justify-stretch items-stretch gap-4'>
                  <div
                    className={cn('min-w-[6px] min-h-[90px] rounded-md', {
                      'bg-gray-400': !item.status,
                      'bg-rust': ['low', 'high', 'out of range'].includes(
                        item.status?.toLowerCase()
                      ),
                      'bg-neue-kelp-200': ['normal', 'optimal', 'highly optimal'].includes(
                        item.status?.toLowerCase()
                      ),
                    })}
                  />
                  <div className='flex items-center'>{item.metric}</div>
                </div>
              );
            },
          };
        }
        if (key === 'result') {
          return {
            key,
            className: 'min-w-[103px]',
            label: 'Result',
            renderItem: (item) => {
              if (!item.result) {
                return <ScoreCardEmptyCell />;
              }
              return (
                <ScoreCardHistoricalCell
                  date={undefined}
                  value={item.result.value?.toString()}
                  units={item.result.units}
                  onClick={() => navigateToResult(item.historical[0]?.documentId)}
                />
              );
            },
          };
        }
        if (key === 'atriaOptimalRange') {
          return {
            key,
            label: (
              <Tooltip text='The Atria preventive range is the range we recommend for longevity.'>
                Atria optimal range
              </Tooltip>
            ),
            className: 'min-w-[145px]',
            renderItem: (item) => {
              if (!item.atriaOptimalRange) {
                return <ScoreCardEmptyCell />;
              }
              return <ScoreCardCell label={item.atriaOptimalRange} />;
            },
          };
        }
        if (key === 'date') {
          return {
            key,
            label,
            className: 'min-w-[89px]',
            renderItem: (item) => {
              if (!item.date) {
                return <ScoreCardEmptyCell />;
              }
              return (
                <div className='w-full justify-center flex items-center'>
                  <p className='text-[11px] font-medium leading-4 -tracking-[0.11px] text-gray-500'>
                    {DateTime.fromJSDate(new Date(item.date)).toFormat('MMM yyyy')}
                  </p>
                </div>
              );
            },
          };
        }
        if (key === 'status') {
          return {
            key,
            className: 'min-w-[131px]',
            label: (
              <Tooltip text='The status of your historical finding. Note that this status comes from the lab and may be related to a different reference range from the one you saw on the left.'>
                {label}
              </Tooltip>
            ),
            renderItem: (item, itemKey) => {
              return <ScoreCardStatusCell status={item} statusKey={itemKey} />;
            },
          };
        }
        if (key === 'referenceRange') {
          return {
            key,
            label,
            className: 'min-w-[130px]',
            renderItem: (item) => {
              const historical = (item.historical || []).find((h) => h.referenceRangeLabel);
              const referenceRangeLabel = historical?.referenceRangeLabel;
              if (!referenceRangeLabel) {
                return <ScoreCardEmptyCell />;
              }
              return <ScoreCardCell label={referenceRangeLabel} />;
            },
          };
        }
        if (key === 'trend') {
          return {
            key,
            label: (
              <Tooltip text='This trend shows whether your most recent finding is higher or lower than the previous finding.'>
                Trend
              </Tooltip>
            ),
            className: 'min-w-[160px] [&>*:first-child]:justify-center',
            renderItem: (item: any) => {
              return (
                <div className='w-full flex items-center'>
                  <ScorecardChartRow trend={item.historical} unitGroups={item.unitGroups} />
                </div>
              );
            },
          };
        }
        return { key, label, ...rest };
      });
    },
    [navigateToResult]
  );

  const getHistoricalHeaders = useCallback(
    (headers: CollapseTableHeaderItem[]): CollapseTableHeaderItem<BiomarkerHistorical>[] => {
      return headers?.map(({ key, label }) => {
        return {
          key,
          label,
          className: 'min-w-[120px] w-[120px]',
          renderItem: (historical, itemKey: string) => {
            const validHistorical = historical.filter((item) => {
              const dateKey = DateTime.fromISO(item.date!, { setZone: true })
                .toUTC()
                .toFormat('MMM_yyyy')
                ?.toLowerCase();
              return dateKey === itemKey;
            });

            if (validHistorical.length === 0) {
              return <ScoreCardEmptyCell />;
            }

            const list = validHistorical.map((item) => {
              const date = DateTime.fromISO(item.date, { setZone: true }).toFormat('MMM dd');
              return (
                <ScoreCardHistoricalCell
                  key={item.documentId}
                  date={validHistorical.length > 1 ? date : undefined}
                  value={item.value}
                  units={item.units}
                  onClick={() => navigateToResult(item.documentId)}
                />
              );
            });

            return <div className='flex-col'>{list}</div>;
          },
        };
      });
    },
    [navigateToResult]
  );

  const getHeaders = useCallback(
    (headers: Header[][]): any[][] => {
      return headers?.map((header, headerIndex) => {
        if (headerIndex === 0) {
          return getAnalyteHeaders(header);
        }
        return getHistoricalHeaders(header);
      });
    },
    [getAnalyteHeaders, getHistoricalHeaders]
  );

  return {
    separators,
    getTableFilters,
    filterGroups,
    getHeaders,
  };
}

import { useContactDialogContext } from '@/contexts';
import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DialogModalChangeAppointment } from '../modal/dialogModal/dialogModalChangeAppointment';

type Props = {
  podPhone?: string;
  patientPhone: string;
  appointmentDate: string;
};

export function PhoneCallCard({ podPhone, patientPhone, appointmentDate }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContent,
    setContactDialogTitle,
    setContactDialogNote,
    setCurrentAppointmentData,
  } = useContactDialogContext();

  const changePhoneNumber = useCallback(() => {
    setContent(<DialogModalChangeAppointment />);
    setCurrentAppointmentData({
      type: 'Phone',
      patientPhone,
      podPhone,
      appointmentDate,
    });
    setContactDialogTitle('Update appointment');
    setContactDialogNote(`For time-sensitive concerns, call or text ${podPhone}`);
    setContactDialogVisibility(!isContactDialogVisible);
  }, [
    podPhone,
    patientPhone,
    isContactDialogVisible,
    appointmentDate,
    setContactDialogNote,
    setContactDialogTitle,
    setContactDialogVisibility,
    setContent,
    setCurrentAppointmentData,
  ]);

  return (
    <>
      <h3 className={cn(isMobile ? TextLayout.h3.ivar : TextLayout.h4.ivar, 'text-neue-kelp-400')}>
        Phone call
      </h3>

      <div className='flex w-full flex-col items-start justify-start gap-5'>
        <div className={cn(TextLayout.callout3, 'text-gray-500')}>
          We will call you at{patientPhone ? ` ${patientPhone} at` : ''} the time of your
          appointment.
        </div>

        {patientPhone && (
          <div className='flex w-full flex-col gap-3 lg:flex-row lg:min-w-[267px]'>
            <Button
              theme='dark'
              variant='secondary'
              size='large'
              label='Change phone number'
              onClick={changePhoneNumber}
              className='w-full lg:w-1/2'
            />
          </div>
        )}
      </div>
    </>
  );
}

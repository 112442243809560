import { CustomTableHeader, CustomTableItems } from '@/@types';
import { MemberPortal } from '@/@types/memberPortal';
import { useContactDialogContext } from '@/contexts';
import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useCallback, useMemo } from 'react';
import { TableCard } from '../card/tableCard';
import { ScorecardChartRow } from './scorecardChartRow';
import { ScorecardCustomTable } from './scorecardCustomTable';

type Trend = MemberPortal.AtriaTargets.TrendItem[];

type Props = {
  title: string;
  description?: string;
  howToImprove?: string;
  trend?: Trend;
  unitGroups: string[][];
};

export function ScorecardExpandedContent({
  description,
  title,
  howToImprove = 'For personalized recommendations, please consult your care team.',
  trend: historical,
  unitGroups,
}: Props) {
  const { setContactDialogVisibility, setContactDialogTopic } = useContactDialogContext();
  const openAskQuestionModal = useCallback(() => {
    setContactDialogTopic(`Question about ${title} results`);
    setContactDialogVisibility(true);
  }, [setContactDialogTopic, setContactDialogVisibility, title]);

  const navigateToResult = useCallback((documentId: number) => {
    window.open(`/results/lab/${documentId}`, '_blank');
  }, []);

  const tableHeaders: CustomTableHeader[] = [
    {
      label: 'Date',
      key: 'date',
    },
    {
      label: 'Value',
      key: 'value',
      width: 200,
    },
    {
      label: 'Original Source',
      key: 'source',
      width: 200,
    },
  ];

  const historicalMapping: CustomTableItems[] = useMemo(() => {
    if (!historical) return [];
    return historical.map((analyte: any) => ({
      date: new Date(analyte.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      value: `${analyte.value} ${analyte.units || ''}`,
      source: (
        <button
          className='text-gray-300 underline underline-offset-[5px] hover:text-neue-kelp-600 gap-2'
          onClick={() => navigateToResult(analyte?.documentId)}
        >
          <span className='text-neue-kelp-400'>View</span>
        </button>
      ),
      status: analyte?.status,
    }));
  }, [historical, navigateToResult]);

  const groupedByUnits = useMemo(() => {
    return Object.values(
      (historical ?? [])?.reduce<Record<string, Trend>>(
        (acc, item) => {
          const unit = item.units || '';
          if (unitGroups.length > 0) {
            const unitGroup = unitGroups.find((group) => group.includes(unit.toLowerCase()));
            if (unitGroup) {
              if (!acc[unitGroup[0]]) {
                acc[unitGroup[0]] = [];
              }
              acc[unitGroup[0]].push(item);
              return acc;
            }
          }

          if (!acc[unit]) {
            acc[unit] = [];
          }

          acc[unit].push(item);

          return acc;
        },
        {} as Record<string, Trend>
      )
    );
  }, [historical, unitGroups]);

  return (
    <div className='flex w-full flex-col rounded-[8px] pointer-events-auto bg-[#DCD8D04D] border border-neue-stone-500 p-[6px] space-y-3'>
      {description && (
        <TableCard title='About'>
          <p className={cn(TextLayout.body2, 'text-product-gray-500')}>{description}</p>
        </TableCard>
      )}

      <TableCard title='How To Improve'>
        <p className={cn(TextLayout.body2, 'text-product-gray-500 mb-6')}>{howToImprove}</p>
        <Button
          variant='secondary'
          onClick={openAskQuestionModal}
          size='medium'
          label='Ask a question'
        />
      </TableCard>

      <TableCard title='History'>
        <div>
          {groupedByUnits?.map((historic, idx) => {
            return (
              historic?.some((x) => !isNaN(Number(x.value))) && (
                <div key={idx}>
                  {groupedByUnits.length > 1 && (
                    <div className='mb-4'>
                      <span className='text-product-500'>
                        {`${historic?.length || 0} result${historic?.length === 1 ? '' : 's'}${historic?.[0]?.units ? ` in ${historic[0].units}` : ''}`}
                      </span>
                    </div>
                  )}
                  <div className='mb-4'>
                    <ScorecardChartRow
                      trend={historic as any}
                      width={450}
                      height={300}
                      unitGroups={unitGroups}
                      showLabels={false}
                    />
                  </div>
                </div>
              )
            );
          })}
          <div>
            <ScorecardCustomTable headers={tableHeaders} items={historicalMapping} />
          </div>
        </div>
      </TableCard>
    </div>
  );
}

import { cn, TextLayout } from '@lib-atria/ui-toolkit';

type Props = {
  title: string;
  children: React.ReactNode;
};

export function TableCard({ title, children }: Props) {
  return (
    <div className='grid lg:grid-cols-[200px_1fr] gap-2 w-full bg-neue-stone-400 rounded-lg px-4 py-[12px]'>
      <p className={cn(TextLayout.callout3, 'text-neue-kelp-400 ')}>{title}</p>
      <div>{children}</div>
    </div>
  );
}

import { TrendChart } from '@lib-atria/ui-toolkit';
import { useMemo } from 'react';

type Operator = 'range' | '>' | '<' | '=' | '>=' | '<=';

type ReferenceRange = {
  operator: string;
  range: {
    min: number;
    max: number;
  };
  statuses: string;
  optimal: boolean;
};

type Range = { min?: number; max?: number };

type Trend = {
  documentId: number;
  value: number | string;
  units: string;
  status: string;
  referenceRange: ReferenceRange[] | Range;
  date: string;
};

type Props = {
  trend: Trend[];
  width?: number;
  height?: number;
  showLabels?: boolean;
  unitGroups?: string[][];
};

type ChartData = {
  data: Array<{ date: Date; value: number; units?: string; status: string }>;
  thresholds: {
    optimal: {
      operator?: Operator;
      range: {
        min: number;
        max: number;
      };
    };
    good?: {
      operator?: Operator;
      range: {
        min: number;
        max: number;
      };
    };
  };
};

export function ScorecardChartRow({ trend, width, height, showLabels, unitGroups = [] }: Props) {
  const { data, thresholds } = useMemo(() => {
    const chartData: ChartData = {
      data: [],
      thresholds: {
        optimal: { operator: 'range', range: { min: 0, max: 0 } },
      },
    };

    if (!trend || typeof trend === 'string') {
      return chartData;
    }

    const latestResult = trend.reduce((previous: Trend | null, current: Trend) => {
      if (!previous) {
        return current;
      }

      return new Date(previous.date) > new Date(current.date) ? previous : current;
    }, null);

    if (!latestResult?.referenceRange || Number.isNaN(Number(latestResult.value))) return chartData;

    const getFilteredTrend = (): Trend[] => {
      if (unitGroups.length > 0) {
        const unitGroup = unitGroups.find((group) =>
          group.includes(latestResult.units?.toLocaleLowerCase())
        );
        return unitGroup
          ? trend.filter(({ units }) => unitGroup.includes(units?.toLowerCase()))
          : trend.filter(({ units }) => units === latestResult.units);
      }
      return trend.filter(({ units }) => units === latestResult.units);
    };
    const filteredTrend = getFilteredTrend();

    const maxValue = Number(
      filteredTrend
        .map(({ value }) => value)
        .reduce((max, value) => (value > max ? value : max), 0)
        .toString()
        .replace(/>|</g, '')
    );
    if (Array.isArray(latestResult.referenceRange)) {
      const referenceRange = (latestResult.referenceRange as ReferenceRange[]).find(
        (range) => range.optimal
      );
      const highlyOptimalRange = (latestResult.referenceRange as ReferenceRange[]).find(
        (range) => range.statuses === 'Highly Optimal'
      );
      if (highlyOptimalRange && referenceRange) {
        chartData.thresholds.optimal.range.min = highlyOptimalRange.range.min;
        chartData.thresholds.optimal.range.max = highlyOptimalRange.range.max || maxValue;
        chartData.thresholds.optimal.operator = highlyOptimalRange.operator as Operator;

        chartData.thresholds.good = {
          operator: referenceRange.operator as Operator,
          range: { min: referenceRange.range.min, max: referenceRange.range.max },
        };
      } else if (referenceRange) {
        chartData.thresholds.optimal.range.min = referenceRange.range.min;
        chartData.thresholds.optimal.range.max = referenceRange.range.max || maxValue;
        chartData.thresholds.optimal.operator = referenceRange.operator as Operator;
      }
    } else {
      const referenceRange = latestResult.referenceRange;
      chartData.thresholds.optimal.range.max = (referenceRange as Range).max || maxValue;
      chartData.thresholds.optimal.range.min = (referenceRange as Range).min || 0;
      chartData.thresholds.optimal.operator = 'range';
    }

    chartData.data = filteredTrend
      .filter(({ value }) => !!value)
      .map(({ value, units, date, status }) => ({
        value: Number(value.toString().replace(/>|</g, '')),
        units,
        date: new Date(date),
        status,
      }));

    return chartData;
  }, [unitGroups, trend]);
  return (
    <TrendChart
      data={data}
      thresholds={thresholds}
      width={width || 160}
      height={height || 100}
      hideLabels={!showLabels}
    />
  );
}

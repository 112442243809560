type Props = {
  label: string;
};

export function ScoreCardCell({ label }: Props) {
  return (
    <div className='flex rounded-full px-4 py-1 bg-product-sand-400 items-center'>
      <span className='text-neue-kelp-400 text-sm font-medium leading-4 -tracking-[0.35px]'>
        {label}
      </span>
    </div>
  );
}

import { MemberPortal } from '@/@types';
import {
  cn,
  CollapseTable,
  CollapseTableFilter,
  CollapseTableItem,
  TextLayout,
} from '@lib-atria/ui-toolkit';
import { useCallback, useMemo, useState } from 'react';
import { TableIdentifier, useScoreCardTable } from './useScoreCardTable';

type Props = {
  identifier: TableIdentifier;
  filterPosition?: 'horizontal' | 'vertical';
  title?: string;
  description?: string;
  cards: MemberPortal.FindAllBiomarkers.AllAnalytesTableList;
};

type SearchFilter = (item: CollapseTableItem, value?: string | null) => boolean;

export function ScorecardTable({
  identifier,
  filterPosition = 'horizontal',
  cards,
  title,
  description,
}: Props) {
  const { getTableFilters, filterGroups, getHeaders, separators } = useScoreCardTable({ cards });
  const [itemsFiltered, setItemsFiltered] = useState<CollapseTableItem[][]>([]);

  const allItems = useMemo(
    () => cards.flatMap((c) => c.items.map((item) => [item, item.historical])),
    [cards]
  );

  const handleOnItemsFilter = useCallback((itemsList: CollapseTableItem[][]) => {
    setItemsFiltered(itemsList);
  }, []);

  const searchFilter: SearchFilter = (item, value) => {
    if (!value) return true;
    return item?.metric?.toLowerCase()?.includes(value.toLowerCase());
  };

  const filterClassName = useMemo(
    () =>
      filterPosition === 'vertical' ? 'overflow-x-visible w-fit max-w-[180px] sticky top-0' : '',
    [filterPosition]
  );

  return (
    <div className='w-full max-w-full mt-8'>
      {title && (
        <div className='flex flex-col items-start justify-between my-7 pt-7 border-t border-kelp'>
          <div className='flex-1'>
            <h1 className={cn(TextLayout.h1.scto, 'text-kelp w-full md:max-w-[50%]')}>{title}</h1>
          </div>
          {description && (
            <div className='flex-1 w-full items-end justify-end flex flex-row'>
              <p className={cn(TextLayout.callout3, 'md:max-w-[35%] text-neue-kelp-400/60')}>
                {description}
              </p>
            </div>
          )}
        </div>
      )}
      <div
        className={cn(
          'items-start w-full grid',
          filterPosition === 'vertical' ? 'flex flex-row h-full xas' : 'gap-6  grid-cols-1'
        )}
      >
        <CollapseTableFilter
          disposition={filterPosition}
          filters={getTableFilters(identifier) as any[]}
          filterGroups={filterGroups}
          items={allItems}
          onFilteredItemsChange={handleOnItemsFilter}
          className={filterClassName}
          searchFilter={searchFilter}
        />

        <div
          className={cn(
            'flex flex-col gap-[10px]',
            filterPosition === 'vertical' ? 'overflow-y-auto xacol' : ''
          )}
        >
          {cards
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((card) => {
              let pageSize = 5;
              const list = (itemsFiltered || []).filter((i) => {
                return i.some((item) => item.__tag__ === card.tag);
              });
              if (list.length === 0) {
                return null;
              }
              if (card.tag == 'atriaTargets') {
                pageSize = list.length;
                list.sort(
                  (a, b) =>
                    a[0].category.localeCompare(b[0].category) ||
                    a[0].metric.localeCompare(b[0].metric)
                );
              }
              card.headers[1].sort((a, b) =>
                b.date && a.date
                  ? new Date(b.date).getTime() - new Date(a.date).getTime()
                  : Number.NEGATIVE_INFINITY
              );
              return (
                <CollapseTable
                  key={card.tag}
                  title={card.title}
                  pageSize={pageSize}
                  items={list}
                  separators={separators}
                  headers={getHeaders(card.headers)}
                  expandableConfig={{ key: 'expandableContent', contentIndex: 0 }}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useMediaQuery } from 'react-responsive';
import { CustomTooltip } from '../tooltip';

type TelehealthCardProps = {
  conferenceLink?: string | null;
  conferenceId?: string | null;
  conferencePassword?: string | null;
  phoneNumber?: string | null;
  podEmail?: string | null;
  podPhone?: string | null;
};

export function TelehealthCard({
  conferenceLink,
  conferenceId,
  conferencePassword,
  podEmail,
  podPhone,
}: TelehealthCardProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <>
      <h3 className={cn(isMobile ? TextLayout.h3.ivar : TextLayout.h4.ivar, 'text-neue-kelp-400')}>
        Video visit
      </h3>

      <div className='flex w-full flex-col items-start justify-start gap-5'>
        <div className={cn(TextLayout.callout3, 'text-gray-500')}>
          Please use the following link to enter your video visit.
        </div>

        <div className='flex w-full flex-col gap-4 2xl:flex-row'>
          <div className='w-full'>
            <Button
              theme='dark'
              variant='primary'
              size='large'
              label='Enter video visit'
              onClick={() =>
                conferenceLink && window.open(conferenceLink, '_blank', 'noopener,noreferrer')
              }
              className='w-full lg:min-w-[267px]'
            />
          </div>

          <CustomTooltip
            content={
              <div className='mt-4 p-6 rounded-lg bg-neue-kelp-400 text-stone flex flex-col gap-5'>
                <p className={TextLayout.body1}>
                  For assistance, reach out to your care team at {podPhone} or {podEmail}
                </p>

                {conferenceId && conferencePassword && (
                  <>
                    <p className={TextLayout.body1}>Zoom details:</p>

                    <div>
                      <p className={TextLayout.body1}>Meeting ID: {conferenceId}</p>
                      <p className={TextLayout.body1}>Passcode: {conferencePassword}</p>
                    </div>
                  </>
                )}
              </div>
            }
            className='w-full'
          >
            <Button
              theme='dark'
              variant='secondary'
              size='large'
              label='Joining instructions'
              className='w-full lg:min-w-[267px] '
            />
          </CustomTooltip>
        </div>
      </div>
    </>
  );
}

import { MemberPortal } from '@/@types';
import { api } from '@/providers';

function signIn(params: { patientId: number }) {
  return api.patch<MemberPortal.SignIn.Response>('/api/v1/member-portal/sign-in', {
    patientId: params.patientId,
  });
}

function getPatientScorecardAthena(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/all-analytes-athena`
  );
}

function getPatientAllBiomarkers(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/all-biomarkers`
  );
}

function getPatientBostonScorecardGraphQL(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/boston-heart`
  );
}

function getPatientBostonScorecardAthena(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/boston-heart-athena`
  );
}

function getPatientCBCScorecard(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/cbc`
  );
}

function getPatientCBCScorecardAthena(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/cbc-athena`
  );
}

function getPatientCMPScorecard(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/cmp`
  );
}

function getPatientCMPScorecardAthena(params: { patientId: number }) {
  return api.get<MemberPortal.FindAllBiomarkers.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/cmp-athena`
  );
}

function getPatientAtriaTargets(params: { patientId: number }) {
  return api.get<MemberPortal.AtriaTargets.Response>(
    `/api/v1/member-portal/patients/${params.patientId}/scorecard/atria-targets`
  );
}

export const MemberPortalService = {
  signIn,
  getPatientScorecardAthena,
  getPatientAllBiomarkers,
  getPatientBostonScorecardGraphQL,
  getPatientBostonScorecardAthena,
  getPatientCBCScorecard,
  getPatientCBCScorecardAthena,
  getPatientCMPScorecard,
  getPatientCMPScorecardAthena,
  getPatientAtriaTargets,
};

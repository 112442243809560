import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { SidebarType } from '@/@types';
import { useAuthContext } from '@/contexts';
import { SwitchAccounts } from '../switchAccounts';
import { MainContent } from './mainContent';
import { SidebarDesktop } from './sidebarDesktop';
import { SidebarMobile } from './sidebarMobile';

type Props = {
  variant?: SidebarVariants;
  items: SidebarType.Item[];
  isContactButtonVisible?: boolean;
  userName: string;
  isAdmin?: boolean;
  adminName?: string;
  prefix?: string;
  hasMultiplePatients?: boolean;
  onNavigate: (url: string) => void;
  onSwitchMember?: VoidFunction;
  onSignOut: VoidFunction;
};

export type SidebarVariants = 'forest' | 'gray';

export function Sidebar({
  variant = 'forest',
  items,
  userName,
  isAdmin = false,
  adminName,
  prefix,
  hasMultiplePatients,
  onNavigate,
  onSwitchMember,
  onSignOut,
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useAuthContext();
  const isMobile = useMediaQuery({ query: '(max-width: 1280px)' });

  const handleOnPatientSelect = useCallback(() => {
    if (isMobile) {
      setIsVisible(false);
    }
  }, [isMobile]);

  const verifyIfHasPermission = useMemo(() => {
    const menuItems = structuredClone(items);
    return menuItems
      .map((item) => {
        if (item.items) {
          item.items = item.items
            .filter((subItem) => {
              if (!subItem.permission) {
                return true;
              }
              if (subItem.permission && user?.permissions?.includes(subItem.permission)) {
                return true;
              }
              return false;
            })
            .filter((subItem) => subItem.isToShow !== false);
        }
        return item;
      })
      .filter((item) => {
        if (!item.permission || user?.permissions?.includes(item.permission as any)) {
          return true;
        }
        return false;
      });
  }, [items, user?.permissions]);

  const onHide = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    setIsVisible(!isMobile);
  }, [isMobile]);

  return (
    <>
      {!user?.isAdmin && <SwitchAccounts onSelect={handleOnPatientSelect} />}
      {isMobile ? (
        <SidebarMobile
          variant={variant}
          items={verifyIfHasPermission}
          isVisible={isVisible}
          onHide={onHide}
          userName={userName}
          isAdmin={isAdmin}
          adminName={adminName}
          hasMultiplePatients={hasMultiplePatients}
          onNavigate={onNavigate}
          onSwitchMember={onSwitchMember}
          onSignOut={onSignOut}
        />
      ) : (
        <SidebarDesktop
          variant={variant}
          items={verifyIfHasPermission}
          isVisible={isVisible}
          userName={userName}
          adminName={adminName}
          prefix={prefix}
          onNavigate={onNavigate}
          onSwitchMember={onSwitchMember}
          onSignOut={onSignOut}
        />
      )}
      <MainContent isVisible={isVisible} onSidebarToggle={setIsVisible} />
    </>
  );
}

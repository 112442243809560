import { cn, TextLayout } from '@lib-atria/ui-toolkit';

type Props = {
  className?: string;
};

export function ScoreCardEmptyCell({ className }: Props) {
  return (
    <div className={cn('flex items-center justify-center w-full', className)}>
      <p className={cn(TextLayout.body1, 'text-gray-500 opacity-50')}>—</p>
    </div>
  );
}

import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CopyToClipboard, DialogMobile } from '../_common';
import { CustomTooltip } from '../tooltip';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';

type HomeVisitCardProps = {
  location: {
    street: string;
    complement?: string;
    city: string;
    phoneNumber: string;
    notes: {
      description: string;
    };
    directionMaps: string;
  };
};

export function AppointmentsCardLocation({
  location: { street, complement, city, phoneNumber, notes, directionMaps },
}: HomeVisitCardProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  return (
    <>
      <h3 className={cn(isMobile ? TextLayout.h3.ivar : TextLayout.h4.ivar, 'text-neue-kelp-400')}>
        Location
      </h3>
      <div className='flex flex-col items-start justify-start'>
        <div className={cn(TextLayout.callout3, 'text-gray-500')}>Atria {city}</div>
        <div className={cn(TextLayout.callout3, 'text-gray-500')}>
          <CopyToClipboard copyText={`${street}${complement && `, ${complement}`} `}>
            <span>
              {street}
              {complement && `, ${complement}`}
            </span>
          </CopyToClipboard>

          <CopyToClipboard copyText={phoneNumber.replace(/\./g, ' ')}>
            <span>{phoneNumber}</span>
          </CopyToClipboard>
        </div>

        <div className='flex gap-6'>
          {isMobile ? (
            <span
              className='cursor-pointer text-xl font-medium leading-7 text-gray-500 underline tracking-[-0.2px] underline-offset-2'
              onClick={handleOpenDialog}
              style={{
                textUnderlinePosition: 'under',
                textDecorationSkipInk: 'none',
              }}
            >
              Parking notes
            </span>
          ) : (
            <CustomTooltip
              content={
                <div className='mt-4 p-6 rounded-lg bg-neue-kelp-400 text-stone flex space-x-4 items-center'>
                  <p className={TextLayout.body1}>{notes.description}</p>
                </div>
              }
            >
              <span
                className='cursor-pointer text-xl font-medium leading-7 text-gray-500 underline tracking-[-0.2px] underline-offset-2'
                style={{
                  textUnderlinePosition: 'under',
                  textDecorationSkipInk: 'none',
                }}
              >
                Parking notes
              </span>
            </CustomTooltip>
          )}

          <a
            href={directionMaps}
            className='text-xl font-medium leading-7 text-gray-500 underline tracking-[-0.2px]'
            target='_blank'
            rel='noreferrer'
            style={{
              textUnderlinePosition: 'under',
              textDecorationSkipInk: 'none',
            }}
          >
            Directions
          </a>
        </div>
      </div>

      <AnimatePresence>
        {isDialogOpen && (
          <DialogMobile
            item={
              <div className='flex space-x-5 items-center pb-6'>
                <p>{notes.description}</p>
              </div>
            }
            handleOnClose={() => {
              setIsDialogOpen(false);
            }}
            params={{
              buttonClassNames: 'w-full',
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
}

import { cn } from '@lib-atria/ui-toolkit';

type TableProps = {
  headers: CustomTableHeader[];
  items: CustomTableItems[];
};
type CustomTableHeader = {
  label: string;
  key: string;
  width?: number | string;
};

type CustomTableItems = {
  [key: string]: any;
};

export function ScorecardCustomTable({ headers, items }: TableProps) {
  return (
    <div className=''>
      <table className='table-auto w-[calc(100%-20px)] lg:w-3/4 border-collapse '>
        <thead>
          <tr className='border-b-[2px] border-black '>
            {headers.map((header) => (
              <th
                key={header.key}
                style={{ width: header.width || 'auto' }}
                className='px-4 py-2 text-sm font-medium text-neue-kelp-400 text-center'
              >
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className=' border border-gray-400'>
          {items.length === 0 ? (
            <tr>
              <td
                colSpan={headers.length}
                className='border border-gray-400 px-4 py-2 text-center text-gray-500'
              >
                -
              </td>
            </tr>
          ) : (
            items.map((item, rowIndex) => (
              <tr key={rowIndex} className='border'>
                {headers.map((header) => {
                  return (
                    <td
                      key={header.key}
                      className='px-4 py-2 text-sm text-neue-kelp-400 border border-gray-400 text-center'
                    >
                      {header.key === 'value' ? (
                        <div className='flex items-center text-center justify-center'>
                          <div className='flex items-center gap-2'>
                            <div
                              className={cn(
                                'flex items-center justify-center rounded-full w-2 h-2 text-white',
                                {
                                  'bg-rust': ['low', 'high', 'out of range'].includes(
                                    (item.status || '').toLowerCase()
                                  ),
                                  'bg-neue-kelp-300': [
                                    'normal',
                                    'optimal',
                                    'highly optimal',
                                  ].includes((item.status || '').toLowerCase()),
                                  'bg-product-gray-300': !item.status,
                                }
                              )}
                            />
                            <span className='text-neue-kelp-400 text-sm font-medium leading-4 -tracking-[0.35px]'>
                              {item[header.key]}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <p>{item[header.key]}</p>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

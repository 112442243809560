import { useContactDialogContext } from '@/contexts';
import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DialogModalChangeAppointment } from '../modal/dialogModal/dialogModalChangeAppointment';

type Props = {
  address: string;
  addressNumber?: string | null;
  podPhone?: string;
  appointmentDate: string;
};

export function HomeVisitCard({ address, addressNumber, podPhone, appointmentDate }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContent,
    setContactDialogTitle,
    setContactDialogNote,
    setCurrentAppointmentData,
  } = useContactDialogContext();

  const changeAddress = useCallback(() => {
    setContent(<DialogModalChangeAppointment />);
    setCurrentAppointmentData({
      type: 'Home',
      address,
      addressNumber,
      podPhone,
      appointmentDate,
    });
    setContactDialogTitle('Update appointment');
    setContactDialogNote(`For time-sensitive concerns, call or text ${podPhone}`);
    setContactDialogVisibility(!isContactDialogVisible);
  }, [
    podPhone,
    address,
    addressNumber,
    appointmentDate,
    isContactDialogVisible,
    setContactDialogNote,
    setContactDialogTitle,
    setContactDialogVisibility,
    setContent,
    setCurrentAppointmentData,
  ]);

  return (
    <>
      <h3 className={cn(isMobile ? TextLayout.h3.ivar : TextLayout.h4.ivar, 'text-neue-kelp-400')}>
        Home visit
      </h3>
      <div className='flex w-full flex-col items-start justify-start gap-5'>
        <div className={cn(TextLayout.callout3, 'text-gray-500')}>
          We will visit you at
          {address ? `${addressNumber ? ` ${addressNumber}` : ''} ${address} at` : ''} the time of
          your appointment.
        </div>

        {address && (
          <div className='flex w-full flex-col gap-3 lg:flex-row'>
            <Button
              theme='dark'
              variant='secondary'
              size='large'
              label='Change address'
              onClick={changeAddress}
              className='w-full lg:w-1/2 lg:min-w-[267px]'
            />
          </div>
        )}
      </div>
    </>
  );
}

import { MemberPortal } from '@/@types';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ScoreCardSummarySkeletonLoader } from './scorecardSummarySkeleton';

type props = {
  analytes: MemberPortal.FindAllBiomarkers.AllAnalytesTableList | null;
};

export function ScorecardSummary({ analytes: allAnalytesCards }: props) {
  const [biomarkersCounter, setBiomarkersCounter] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [outOfRangeCount, setOutOfRangeCount] = useState(0);
  const [normalCount, setNormalCount] = useState(0);
  const [outOfRangeHeight, setOutOfRangeHeight] = useState(372);
  const [normalHeight, setNormalHeight] = useState(372);
  const [selectedGroup, setSelectedGroup] = useState('All');
  const [loading, setLoading] = useState(false);

  const groupsNames = useMemo(() => {
    if (!allAnalytesCards?.length) return [];

    const filteredTitles = allAnalytesCards
      .map(({ title }) => title)
      .filter((title) => title !== 'Other')
      .sort();

    return ['All', ...filteredTitles, 'Other'];
  }, [allAnalytesCards]);

  useEffect(() => {
    setLoading(true);
    const filteredGroups = allAnalytesCards?.filter((group) => {
      if (selectedGroup === 'All') {
        return true;
      }

      return group.tag === selectedGroup;
    });
    if (!filteredGroups) return;

    const groupOverviews = filteredGroups
      .filter((item) => item.overview)
      .map(({ overview }) => overview!);
    let normalItems = 0;
    let outOfRangeItems = 0;
    if (selectedGroup === 'All') {
      const startedAt = groupOverviews.reduce(
        (previous, { startAt }) => {
          const date = DateTime.fromISO(startAt);
          if (previous === null || date.toMillis() < previous.toMillis()) {
            return date;
          }
          return previous;
        },
        null as DateTime | null
      );

      const endedAt = groupOverviews.reduce(
        (previous, { endAt }) => {
          const date = DateTime.fromISO(endAt);
          if (previous === null || date.toMillis() > previous.toMillis()) {
            return date;
          }
          return previous;
        },
        null as DateTime | null
      );
      normalItems = groupOverviews.reduce((sum, { normal }) => sum + normal, 0);
      outOfRangeItems = groupOverviews.reduce((sum, { outOfRange }) => sum + outOfRange, 0);

      setStartDate(startedAt?.toFormat('LLL yyyy') || '');
      setEndDate(endedAt?.toFormat('LLL yyyy') || '');
      setBiomarkersCounter(
        groupOverviews.map(({ count }) => count).reduce((acc, total) => acc + total, 0)
      );
    } else {
      const biomarkers = filteredGroups.reduce((sum, group) => {
        const groupItems = group.items || [];
        groupItems.forEach((item) => {
          if (['normal', 'optimal'].includes((item.status || '').toLowerCase())) {
            normalItems++;
          } else if (item.status !== null) {
            outOfRangeItems++;
          }
        });

        return sum + groupItems.length;
      }, 0);
      const groupStartAt = DateTime.fromISO(groupOverviews[0]?.startAt || '');
      const groupEndAt = DateTime.fromISO(groupOverviews[0]?.endAt || '');

      setStartDate(groupStartAt?.isValid ? groupStartAt.toFormat('LLL yyyy') : '');
      setEndDate(groupEndAt?.isValid ? groupEndAt.toFormat('LLL yyyy') : '');
      setBiomarkersCounter(biomarkers);
    }
    setNormalCount(normalItems!);
    setOutOfRangeCount(outOfRangeItems!);
    if (normalItems > outOfRangeItems) {
      setOutOfRangeHeight(372 * (outOfRangeItems / normalItems));
      setNormalHeight(372);
    } else {
      setNormalHeight(372 * (normalItems / outOfRangeItems));
      setOutOfRangeHeight(372);
    }

    setLoading(false);
  }, [allAnalytesCards, groupsNames, selectedGroup]);

  const onSelectGroup = useCallback(
    (groupName: string) => () => {
      setSelectedGroup(groupName);
    },
    []
  );

  return (
    <div className='flex flex-row gap-1'>
      <div className='flex w-full flex-col justify-between bg-kelp text-white rounded-lg py-6 px-8'>
        <h2 className={cn(TextLayout.h2.scto)}>Overview</h2>
        <table className='w-full text-left'>
          <thead>
            <tr>
              <th className={cn(TextLayout.body2, 'text-sm py-[1px] w-[50%]')}></th>
              <th className={cn(TextLayout.body2, 'text-sm py-[1px] w-[50%]')}></th>
            </tr>
          </thead>
          <tbody className={cn(TextLayout.body2, 'text-sm')}>
            {groupsNames.map((groupName, index) => (
              <tr
                key={index}
                className={cn(
                  'cursor-pointer text-white opacity-60 hover:opacity-100',
                  TextLayout.body2,
                  {
                    'opacity-100': groupName === selectedGroup,
                  }
                )}
                onClick={onSelectGroup(groupName)}
              >
                <td className='py-[1px]'>
                  {index + 1 < 10 && '0'}
                  {index + 1}
                </td>
                <td className='py-[1px]'>{groupName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='w-full bg-kelp text-white rounded-lg py-6 px-8'>
        {loading ? (
          <ScoreCardSummarySkeletonLoader />
        ) : (
          <>
            <h2 className={cn(TextLayout.callout3, 'text-base  text-white')}>
              {biomarkersCounter} biomarker{biomarkersCounter !== 1 ? 's' : ''}
            </h2>
            <p className={cn(TextLayout.body1, 'text-base  text-white opacity-55 mb-6')}>
              {startDate} - {endDate}
            </p>
            <p className={cn(TextLayout.body1, 'text-base  text-white opacity-55 mb-16')}>
              Don&apos;t be concerned if any biomarkers are out of range — your doctor will reach
              out if anything needs your attention.
            </p>
            <div className='flex flex-row gap-1 text-kelp items-end justify-center bg-horizontal-lines-background pt-6 px-12'>
              <div
                className='flex flex-col w-full max-w-[152px] bg-white justify-end items-center rounded-[4px]  min-h-[100px]'
                style={{
                  height: `${normalHeight}px`,
                }}
              >
                <span className={cn(TextLayout.callout1)}>{normalCount}</span>
                <span className={cn(TextLayout.body2, 'text-sm text-product-gray-500 mb-6')}>
                  Optimal / Normal
                </span>
              </div>
              <motion.div
                className='flex flex-col w-full max-w-[152px] bg-white justify-end items-center rounded-[4px] min-h-[100px]'
                style={{
                  height: `${outOfRangeHeight}px`,
                }}
                initial={{ height: '0px', opacity: 0 }}
                animate={{
                  height: `${outOfRangeHeight}px`,
                  opacity: 1,
                }}
                transition={{
                  type: 'spring',
                  stiffness: 100,
                  damping: 20,
                  duration: 0.8,
                }}
              >
                <span className={cn(TextLayout.callout1)}>{outOfRangeCount}</span>
                <span className={cn(TextLayout.body2, 'text-sm text-product-gray-500 mb-6')}>
                  Out of range
                </span>
              </motion.div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

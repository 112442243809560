import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from '@lib-atria/ui-toolkit';

import { useAuthContext, useLoaderContext, usePageContext } from '@/contexts';
import { InputText } from 'primereact/inputtext';

export interface Summary {
  patient_id?: number;
  google_doc?: string;
  executive_summary?: string;
  medical_summary?: string;
  spikes?: string;
  trends?: string;
  message?: string; // error
}

export function ArtificialIntelligencePage() {
  const { setPageTitle } = usePageContext();
  const { startLoader, stopLoader } = useLoaderContext();
  const [patientId, setPatientId] = useState<any>(null);
  const { user } = useAuthContext();
  const [summary, setSummary] = useState<Summary>();
  const goMLUrl = 'https://ai-api.atria.org/summary';

  useEffect(() => {
    setPageTitle('Summary (goML)');
  }, [setPageTitle]);

  const getSummary = useCallback(async () => {
    try {
      startLoader();
      const { data } = await axios.post(goMLUrl, {
        patient_id: patientId,
        user_type: 'Patient',
        summary_type: 'Detailed',
        user_email: user!.email,
        default_email: 'gomlapi@atria.org',
      });
      setSummary(data);
    } finally {
      stopLoader();
    }
  }, [patientId, startLoader, stopLoader, user]);

  return (
    <div className='w-full'>
      <div className='flex justify-between'>
        <div className='w-4/6 flex items-start justify-start gap-2'>
          <InputText
            value={patientId}
            onChange={(e) => setPatientId(e.target.value)}
            placeholder='Patient ID'
            className='flex-1 rounded-[42px] outline-none px-9 py-5'
          />
          <Button
            variant='secondary'
            icon='search'
            size='large'
            iconVisibility='mobile-only'
            textVisibility='desktop-only'
            label='Generate Summary'
            disabled={!patientId}
            onClick={getSummary}
          />
        </div>
        {summary?.google_doc && (
          <Button
            className='mb-5'
            icon='pi pi-external-link'
            label='Google Docs'
            size='small'
            type='submit'
            onClick={() => window.open(summary?.google_doc, '_blank')}
          />
        )}
      </div>
      {summary && (
        <div className='w-12/12 border-solid border-2 p-4 rounded-lg'>
          {summary?.message ? (
            <>{summary?.message}</>
          ) : (
            <>
              <div className='mb-8'>
                <div className='text-lg font-semibold'>Executive Summary</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{
                    __html: summary?.executive_summary || '-',
                  }}
                />
              </div>
              <div className='mb-8'>
                <div className='text-lg font-semibold'>Medical Summary</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{
                    __html: summary?.medical_summary || '-',
                  }}
                />
              </div>
              <div className='mb-8'>
                <div className='text-lg font-semibold'>Spikes</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{ __html: summary?.spikes || '-' }}
                />
              </div>
              <div>
                <div className='text-lg font-semibold'>Trends</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{ __html: summary?.trends || '-' }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

import { BigButton } from '@/components/button';
import { motion } from 'framer-motion';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Checkbox, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { DialogModalChangeAppointmentFormType } from './dialogModalChangeAppointmentSchema';

import styles from './dialogModal.module.css';
import { useAuthContext, useContactDialogContext } from '@/contexts';
import { usePatients } from '@/hooks';
import { eventAnalytics } from '@/providers';

export function DialogModalChangeAppointment() {
  const {
    currentAppointmentData,
    setConfirmPage,
    analyticsParentPage,
    analyticsFormSubmittedEvent,
  } = useContactDialogContext();
  const { control, formState, handleSubmit, setError, reset, setValue } =
    useFormContext<DialogModalChangeAppointmentFormType>();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { registerContactCMO } = usePatients();
  const { patient } = useAuthContext();

  const isPhoneNumberType = useMemo(
    () => currentAppointmentData?.type === 'Phone',
    [currentAppointmentData?.type]
  );

  useEffect(() => {
    setValue('updateForAllAppointments', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async (form: DialogModalChangeAppointmentFormType) => {
      const currentField = isPhoneNumberType ? 'phoneNumber' : 'address';
      try {
        if (isPhoneNumberType ? !form.phoneNumber : !form.address) {
          setError(currentField, {
            type: 'manual',
            message: 'Field is required',
          });

          return;
        }

        if (!patient?.id || !patient?.cmoEmail) return;

        const updateForAllAppointmentsMessage = `member has requested that this ${isPhoneNumberType ? 'number' : 'address'} is updated for ${form.updateForAllAppointments ?? true ? `all future ${isPhoneNumberType ? 'phone calls' : 'home visits'}` : `this appointment only`}`;
        const message = `Request to change address for their ${currentAppointmentData?.appointmentDate} ${isPhoneNumberType ? 'phone call' : 'home visit'} appointment, ${updateForAllAppointmentsMessage} - New ${isPhoneNumberType ? 'phone number' : 'address'}: ${isPhoneNumberType ? form.phoneNumber : form.address}`;

        await registerContactCMO({
          to: patient?.cmoEmail,
          title: 'Update appointment',
          body: message,
          patient: {
            id: patient?.id,
            firstName: patient.firstName,
            lastName: patient.lastName,
          },
        });

        reset();
        setConfirmPage(true);
        eventAnalytics.track(analyticsFormSubmittedEvent, {
          'parent page': analyticsParentPage,
          appointment: 'Update appointment',
          message,
        });
      } catch (error: any) {
        setError(currentField, {
          type: 'manual',
          message: error?.response?.data?.message || error.message,
        });
        return;
      }
    },
    [
      patient?.id,
      patient?.cmoEmail,
      patient?.firstName,
      patient?.lastName,
      setError,
      registerContactCMO,
      reset,
      setConfirmPage,
      analyticsFormSubmittedEvent,
      analyticsParentPage,
      currentAppointmentData?.appointmentDate,
      isPhoneNumberType,
    ]
  );

  return (
    <form
      className='font-medium relative flex flex-col justify-between lg:justify-normal'
      style={{ height: isMobile ? 'calc(100% - 6.5rem)' : 'calc(430px - 4rem)' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col gap-8'>
        <div>
          <p className={cn(TextLayout.body3, 'opacity-50')}>
            {isPhoneNumberType ? 'Current phone number' : 'Current address'}
          </p>

          <p className={cn(TextLayout.body2, 'mt-1 opacity-50')}>
            {isPhoneNumberType
              ? currentAppointmentData?.patientPhone
              : currentAppointmentData?.address}
          </p>
        </div>

        <div>
          <p className={TextLayout.body3}>
            {isPhoneNumberType ? 'Updated phone number' : 'Updated address'}
          </p>

          {currentAppointmentData?.type === 'Home' && (
            <Controller
              control={control}
              name='address'
              render={({ field }) => (
                <InputText
                  name='address'
                  className={cn(
                    TextLayout.body2,
                    'bg-kelp-dark text-white border-none mt-4 w-full h-[52px] rounded-lg px-5 py-5 placeholder-opacity-40',

                    styles.textInput
                  )}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || ''}
                  placeholder='Enter address'
                  minLength={3}
                  required
                />
              )}
            />
          )}

          {currentAppointmentData?.type === 'Phone' && (
            <Controller
              control={control}
              name='phoneNumber'
              render={({ field }) => (
                <InputText
                  name='phoneNumber'
                  className={cn(
                    TextLayout.body2,
                    'bg-kelp-dark text-white border-none mt-4 w-full h-[52px] rounded-lg px-5 py-5 placeholder-opacity-40',

                    styles.textInput
                  )}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || ''}
                  placeholder='Enter phone number'
                  minLength={10}
                  required
                />
              )}
            />
          )}
        </div>

        <div className='flex items-center gap-3'>
          <Controller
            control={control}
            name='updateForAllAppointments'
            render={({ field }) => (
              <Checkbox
                checked={field.value ?? true}
                onChange={(e) => {
                  e.stopPropagation();
                  setValue('updateForAllAppointments', !field.value);
                }}
                variant='tertiary'
              />
            )}
          />

          <p className={TextLayout.body3}>
            Update for all future {isPhoneNumberType ? 'phone calls' : 'home visits'}
          </p>
        </div>
      </div>

      <motion.div
        style={{
          opacity: !formState.isValid ? 0 : 1,
          pointerEvents: formState.isValid ? 'auto' : 'none',
          transition: 'opacity 1.5s',
        }}
        transition={{ duration: 1.5 }}
        className='w-full'
      >
        <BigButton variant='white' className='py-5 px-4 mt-[32px] mb-5'>
          {formState.isSubmitting || formState.isLoading ? (
            <i className='pi pi-spinner animate-spin text-kelp'></i>
          ) : (
            'Send to your care team'
          )}
        </BigButton>
      </motion.div>
    </form>
  );
}

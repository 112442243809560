import { Appointments } from '@/@types';
import { locations } from '@/config/locationsData';
import { AppointmentsCardLocation } from '../appointmentsCard';
import { HomeVisitCard } from './homeVisitCard';
import { PhoneCallCard } from './phoneCallCard';
import { TelehealthCard } from './teleHealthCard';

type Props = {
  appointmentGroup: Appointments.FindAllPatientsAppointments.GroupItem;
  appointmentDate: string;
};

export function UpcomingAppointmentsLocation({ appointmentGroup, appointmentDate }: Props) {
  const appointment = appointmentGroup?.appointments[0];

  switch (appointmentGroup.location?.toLowerCase()) {
    case 'new york':
      return <AppointmentsCardLocation location={locations[0]} />;
    case 'palm beach':
      return <AppointmentsCardLocation location={locations[1]} />;
    case 'telehealth':
      return (
        <TelehealthCard
          conferenceLink={appointment?.conferenceLink}
          podEmail={appointmentGroup?.podEmail}
          podPhone={appointmentGroup?.podPhone}
          conferenceId={appointment?.conferenceId}
          conferencePassword={appointment?.conferencePassword}
        />
      );
    case 'home':
      return (
        <HomeVisitCard
          address={appointmentGroup.address}
          addressNumber={appointment.addressNumber}
          podPhone={appointmentGroup?.doctor?.pods?.podPhone}
          appointmentDate={appointmentDate}
        />
      );
    case 'phone':
      return (
        <PhoneCallCard
          podPhone={appointmentGroup?.doctor?.pods.podPhone}
          patientPhone={appointmentGroup.patientPhone}
          appointmentDate={appointmentDate}
        />
      );
    default:
      return null;
  }
}

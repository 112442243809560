import { cn } from '@lib-atria/ui-toolkit';
import { ScoreCardEmptyCell } from './scoreCardEmptyCell';

type Props = {
  status: Record<string, any> | null;
  statusKey: string;
};

export function ScoreCardStatusCell({ status, statusKey }: Props) {
  if (!status) {
    return <ScoreCardEmptyCell />;
  }
  const [value] = Object.values(status);

  if (!value || !status[statusKey] || status[statusKey].length === 0) {
    return <ScoreCardEmptyCell />;
  }

  const items: { status: string }[] = Array.isArray(value)
    ? value
    : [{ status: status[statusKey] }];

  return (
    <div className='flex flex-col items-center'>
      {items.map((item, index) => (
        <Cell key={index} status={item.status} />
      ))}
    </div>
  );
}

function Cell({ status }: { status: string }) {
  const statusComparison = (status || '')?.toLowerCase();
  return (
    <div className='flex rounded-full px-4 py-[2px] bg-product-sand-400 items-center gap-2'>
      <div
        className={cn('flex rounded-full w-2 h-2 text-white', {
          'bg-rust': ['low', 'high', 'out of range'].includes(statusComparison),
          'bg-neue-kelp-300': ['normal', 'optimal', 'highly optimal'].includes(statusComparison),
        })}
      />
      <span className='text-neue-kelp-400 text-sm font-medium leading-4 -tracking-[0.35px] capitalize'>
        {status}
      </span>
    </div>
  );
}

import { Button } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect, useState } from 'react';

import { Patient } from '@/@types';
import { SearchPatientByName } from '@/components';
import { useApplicationContext, useAuthContext, usePageContext } from '@/contexts';

export function AccessAsAPatientPage() {
  const [patientData, setPatientData] = useState<Patient.Type>();
  const { signInAdmin } = useAuthContext();
  const { reset } = useApplicationContext();

  const { setPageTitle } = usePageContext();

  useEffect(() => {
    setPageTitle('Access as a patient');
  }, [setPageTitle]);

  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit = useCallback(() => {
    if (!patientData) return;
    signInAdmin({
      id: patientData.id,
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      dateOfBirth: patientData.dateOfBirth,
      patientName: patientData.patientName,
      primaryProvider: {
        ...patientData.primaryProvider,
        lastName: patientData?.primaryProvider?.lastName || '',
      },
      email: patientData.email,
      firstNameUsed: patientData.firstName,
    });
  }, [patientData, signInAdmin]);

  return (
    <div className='w-full max-w-xl flex flex-col items-stretch justify-center gap-4'>
      <SearchPatientByName searchBy='lastName' onSelect={setPatientData} />
      <Button
        type='submit'
        variant='primary'
        size='large'
        label='Continue'
        onClick={onSubmit}
        disabled={!patientData}
      />
    </div>
  );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LabResult, LabResultAttachment } from '@/@types';
import { AttachmentViewer, LabResultDetailsCard, Loading } from '@/components';
import { useAuthContext, usePageContext } from '@/contexts';
import { DateHelper } from '@/helper';
import { usePatientLabResults } from '@/hooks';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { DateTime } from 'luxon';

export function LabResultDetailsPage() {
  const { findPatientLabResult, findPatientLabResultAttachment, findPatientLabResultsAnalytes } =
    usePatientLabResults();
  const { patient } = useAuthContext();
  const { setActions, setIsBackButtonVisible } = usePageContext();
  const labResultId = Number(useParams().id);
  const [result, setResult] = useState<LabResult>();
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [attachmentData, setAttachmentData] = useState<LabResultAttachment[]>([]);

  useEffect(() => {
    setIsBackButtonVisible(true, 'Lab results');
  }, [setIsBackButtonVisible]);

  const date = useMemo(() => {
    if (!result) return;
    return DateHelper.formatDateToDisplay(
      DateTime.fromISO(result.observationDateTime ?? result.createdDateTime)
    );
  }, [result]);

  const dateFilename = useMemo(() => {
    if (!result) return;
    return DateTime.fromISO(result.observationDateTime ?? result.createdDateTime).toFormat(
      'yyyy_LL_dd'
    );
  }, [result]);

  const findLabResultAttachment = useCallback(async () => {
    if (!patient?.id) return;
    const attachment = await findPatientLabResultAttachment(patient.id!, labResultId);
    if (attachment) {
      setAttachmentData([attachment]);
      return true;
    }
  }, [findPatientLabResultAttachment, labResultId, patient?.id]);

  const generatePdf = useCallback(async () => {
    if (!result) {
      return;
    }

    const attachment = await findPatientLabResultsAnalytes(patient!.id, result.id);
    if (attachment) {
      const url = window.URL.createObjectURL(attachment!.blob!);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${result.description}_${dateFilename}.${attachment?.type}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);

      eventAnalytics.track(TRACK_EVENTS.LAB_RESULT_DOWNLOADED, {
        'result name': result?.description,
        'result date': date,
      });
    }
  }, [date, dateFilename, findPatientLabResultsAnalytes, patient, result]);

  useEffect(() => {
    async function loadInititalData() {
      if (patient && labResultId) {
        const labResultItem = await findPatientLabResult(patient.id, labResultId);
        if (!labResultItem) return;

        setResult(labResultItem);
        try {
          setIsAttachmentLoading(true);
          await findLabResultAttachment();
        } finally {
          setIsAttachmentLoading(false);
        }
      }
    }

    loadInititalData();
  }, [findLabResultAttachment, findPatientLabResult, labResultId, patient]);

  useEffect(() => {
    if (result && result.observations.length > 0) {
      setActions({
        buttons: [
          {
            icon: 'download',
            label: 'Download',
            onClick: () => generatePdf(),
          },
        ],
      });
    }
  }, [generatePdf, result, setActions]);

  return (
    <div className='w-full flex-1 flex flex-col gap-12'>
      {result && <LabResultDetailsCard labResult={result} />}
      {isAttachmentLoading ? (
        <Loading label='Loading attachments...' />
      ) : (
        attachmentData?.length > 0 && (
          <div className='flex items-start justify-between flex-wrap gap-4'>
            <AttachmentViewer
              attachments={attachmentData}
              options={{
                fileName: `${result!.description}_${dateFilename}.pdf`,
                toolbarVisible: true,
                borderVisible: true,
                pageRounded: true,
              }}
            />
          </div>
        )
      )}
    </div>
  );
}

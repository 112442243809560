import { cn, TextLayout } from '@lib-atria/ui-toolkit';

type Props = {
  date?: string;
  value?: string;
  units?: string;
  onClick: VoidFunction;
};

export function ScoreCardHistoricalCell({ date, value, units, onClick }: Props) {
  return (
    <>
      <div className='flex-1 flex flex-col align-bottom gap-3 items-center justify-center'>
        {!onClick ? (
          <p
            className={cn(
              TextLayout.body1,
              'text-neue-kelp-400 font-medium leading-6 -tracking-[0.4px]'
            )}
          >
            {value}
          </p>
        ) : (
          <div className='flex flex-col items-center'>
            {date && <span className='text-product-gray-500 text-[11px] font-medium'>{date}</span>}
            <button
              className='text-gray-300 underline underline-offset-[5px] hover:text-neue-kelp-600 gap-2'
              onClick={onClick}
            >
              <span
                className={cn(
                  TextLayout.body1,
                  'text-neue-kelp-400 font-medium leading-6 -tracking-[0.4px]'
                )}
              >
                {value}{' '}
                <span className='text-[11px] font-medium leading-4 -tracking-[0.11px] text-gray-500'>
                  {units}
                </span>
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

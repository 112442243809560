import { cn, TextLayout } from '@lib-atria/ui-toolkit';

type Props = {
  status: string;
};

export function LabResultStatus({ status }: Props) {
  if (!status) {
    return '-';
  }

  const statusLower = status.toLowerCase();

  return (
    <div className='flex'>
      <span
        className={cn(
          TextLayout.body2,
          'flex text-center justify-center rounded-full text-white py-1 px-3',
          {
            'bg-rust': ['low', 'high', 'out of range'].includes(statusLower),
            'bg-neue-kelp-300': ['normal', 'optimal', 'highly optimal'].includes(statusLower),
          }
        )}
      >
        {status}
      </span>
    </div>
  );
}

import { SidebarType } from '@/@types';
import { SidebarVariants } from '../sidebar';
import { SidebarListItem } from './sidebarListItem';

type Props = {
  variant?: SidebarVariants;
  items: SidebarType.Item[];
  onClick: (url: string) => void;
  prefix?: string;
};

export function SidebarList({ variant, items, onClick, prefix }: Props) {
  return (
    <ul className='flex-1 w-full flex flex-col gap-3 px-5'>
      {items
        .filter((item) => item.isToShow !== false)
        .map((item) => (
          <SidebarListItem
            key={item.label}
            variant={variant}
            item={item}
            onClick={onClick}
            prefix={prefix}
          />
        ))}
    </ul>
  );
}

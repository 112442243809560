import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';

export function ScoreCardSummarySkeletonLoader() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <ContentLoader
      speed={2}
      width='100%'
      height='100%'
      viewBox={isMobile ? '0 0 100 90' : '0 0 100 60'}
      backgroundColor='#ccc'
      foregroundColor='#ddd'
      preserveAspectRatio='xMinYMin meet'
    >
      {isMobile ? (
        <>
          <rect x='19%' y='25%' rx='3' ry='3' width='25' height='70%' />
          <rect x='49%' y='55%' rx='3' ry='3' width='25' height='40%' />
        </>
      ) : (
        <>
          <rect x='19%' y='25%' rx='3' ry='3' width='25' height='70%' />
          <rect x='49%' y='55%' rx='3' ry='3' width='25' height='40%' />
        </>
      )}
    </ContentLoader>
  );
}
